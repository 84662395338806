<script>
import { mapActions, mapGetters } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'
import { required, requiredIf, between } from 'vuelidate/lib/validators'
import { VMoney } from 'v-money'
import { isNull } from 'lodash'

export default {
  name: 'ManagementGeneral',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    FormSection: () => import('@/components/general/FormSection'),
    Radio: () => import('@/components/general/Radio'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    InputField: () => import('@/components/general/InputField'),
    Tabs: () => import('@/components/general/Tabs'),
    SelectField: () => import('@/components/general/SelectField'),
    Tagify: () => import('@/components/general/Tagify'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  directives: { money: VMoney },

  mixins: [ managementMixin ],

  data () {
    return {
      generalTabs: [],

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      patternOptions: [
        {
          label: this.$t('management.general:metadata.datatable.field.standard'),
          value: false
        },
        {
          label: this.$t('management.general:metadata.datatable.field.association'),
          value: true
        }
      ],

      vitrineOptions: [],
      activeTab: 'sas',
      providerList: [],
      rmList: [],
      sasUnityList: [],
      sasActionList: [],
      sasProjectList: [],
      metadataList: [],
      paymentTypeList: [
        {
          text: 'Cartão de Crédito',
          value: 1,
          alias: 'financial_allows_credit_card'
        },
        {
          text: 'Dois Cartões de Crédito',
          value: 2,
          disabled: true,
          alias: 'financial_allows_two_credit_cards'
        },
        {
          text: 'Boleto',
          value: 3,
          disabled: true,
          alias: 'financial_allows_boleto'
        },
        {
          text: 'Cartão de Débito',
          value: 4,
          disabled: true,
          alias: 'financial_allows_debit_card'
        },
        {
          text: 'PIX',
          value: 5,
          disabled: true,
          alias: 'financial_allows_pix'
        }
      ],

      formData: {
        financial_credit_card_allows_installment: false,
        financial_credit_card_maximum_amount_installments: 2,
        financial_credit_card_minimum_installment_value: '0',
        financial_provider: null,
        financial_allows_credit_card: false,
        paymentType: null,
        rm_enable_integration: false,
        sas_id_project: null,
        sas_id_action: null,
        sas_notify_email: null,
        sas_id_service_type: null,
        sas_id_modality: null,
        sas_id_instrument: null,
        sas_id_responsable: null,
        sas_id_attendance: null,
        sas_id_credentiated_employee: null,
        sas_name_instructure: null,
        sas_id_unity: null,
        sas_address: null,
        sas_complement: null,
        sas_cep: null,
        sas_city_code: null,
        sas_neighborhood_code: null,
        sas_state_code: null,
        search_engine_stop_words: null,
        search_engine_searchable_attributes: null,
        search_engine_synonyms: null,
        rm_provider: null,
        rm_url: null,
        rm_user: null,
        rm_password: null,
        rm_password_base64: null,
        financial_receipt: null,
        studion_api_url: null,
        studion_client_id: null,
        studion_client_secret: null,
        studion_login: null,
        studion_passwd: null,
        studion_spa_url: null,
        studion_default_branch_name: null,
        studion_branch_name: null,
        studion_branch_level: null,
        studion_metas: null,
        studion_metas_relationship: null,
        sparkpost_api_url: null,
        sparkpost_key: null,
        cornerstone_api_url: null,
        cornerstone_client_id: null,
        cornerstone_client_secret: null,
        dot_mailer_api_url: null,
        dot_mailer_api_key: null,
        dot_mailer_api_secret: null,
        dot_mailer_from_name: null,
        dot_mailer_from_email: null,
        dot_mailer_reply_to: null,
        dot_mailer_support_recipient: null,
        gdh_url: null,
        gdh_username: null,
        gdh_password: null
      },

      editable: [],
      synonyms: [],
      searchable_attributes: [],
      searchableAttributesList: [
        {
          text: this.$t('management.general:searchengine.attribute.name'),
          value: 'name'
        },
        {
          text: this.$t('management.general:searchengine.attribute.description'),
          value: 'description'
        },
        {
          text: this.$t('management.general:searchengine.attribute.call_text'),
          value: 'call_text'
        },
        {
          text: this.$t('management.general:searchengine.attribute.audience'),
          value: 'audience'
        },
        {
          text: this.$t('management.general:searchengine.attribute.differentials'),
          value: 'differentials'
        },
        {
          text: this.$t('management.general:searchengine.attribute.tags'),
          value: 'tags'
        }
      ],

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false, // doesn't work with directive
        allowBlank: true
      },

      modalRm: false,

      receiptList: [
        {
          text: 'Padrão',
          value: 'padrao'
        },
        {
          text: 'Alternativo',
          value: 'alternativo'
        }
      ]
    }
  },

  validations: {
    formData: {
      financial_credit_card_allows_installment: {
        required: (event) => {
          return event !== null && event !== undefined
        }
      },

      financial_credit_card_minimum_installment_value: {
        required: requiredIf(function () {
          return this.formData.financial_credit_card_allows_installment
        })
      },

      financial_credit_card_maximum_amount_installments: {
        required: requiredIf(function () {
          return this.formData.financial_credit_card_allows_installment
        }),

        between: between(2, 12)
      },

      paymentType: {
        required
      },

      financial_provider: {
        required
      },

      sas_id_project: {
        type: String,
        required: true
      },

      sas_id_action: {
        type: String,
        required: true
      },

      sas_notify_email: {
        type: String,
        required: true
      },

      sas_id_service_type: {
        type: String,
        required: true
      },

      sas_id_modality: {
        type: String,
        required: true
      },

      sas_id_instrument: {
        type: String,
        required: true
      },

      sas_id_responsable: {
        type: String,
        required: true
      },

      sas_id_attendance: {
        type: String,
        required: true
      },

      sas_id_credentiated_employee: {
        type: String,
        required: true
      },

      sas_name_instructure: {
        type: String,
        required: true
      },

      sas_id_unity: {
        type: String,
        required: true
      },

      sas_address: {
        type: String,
        required: true
      },

      sas_complement: {
        type: String,
        required: true
      },

      sas_cep: {
        type: String,
        required: true
      },

      sas_city_code: {
        type: String,
        required: true
      },

      sas_neighborhood_code: {
        type: String,
        required: true
      },

      sas_state_code: {
        type: String,
        required: true
      },

      search_engine_stop_words: {
        type: String,
        required: false
      },

      search_engine_searchable_attributes: {
        type: String,
        required: false
      },

      search_engine_synonyms: {
        type: String,
        required: false
      },

      rm_enable_integration: {
        required: (event) => {
          return event !== null && event !== undefined
        }
      },

      rm_provider: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_url: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_user: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_password: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      rm_password_base64: {
        required: requiredIf(function () {
          return this.formData.rm_enable_integration
        })
      },

      financial_receipt: {
        required
      },

      studion_api_url: {
        required
      },

      studion_client_id: {
        required
      },

      studion_client_secret: {
        required
      },

      studion_login: {
        required
      },

      studion_passwd: {
        required
      },

      studion_spa_url: {
        required
      },

      studion_metas: {
        required
      },

      sparkpost_api_url: {
        required
      },

      sparkpost_key: {
        required
      },

      cornerstone_api_url: {
        required
      },

      cornerstone_client_id: {
        required
      },

      cornerstone_client_secret: {
        required
      },

      dot_mailer_api_url: {
        required
      },

      dot_mailer_api_key: {
        required
      },

      dot_mailer_api_secret: {
        required
      },

      dot_mailer_from_name: {
        required
      },

      dot_mailer_from_email: {
        required
      },

      dot_mailer_reply_to: {
        required
      },

      dot_mailer_support_recipient: {
        required
      },

      gdh_url: {
        required
      },

      gdh_username: {
        required
      },

      gdh_password: {
        required
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),
    canWriteSearchEngine () {
      return this.getContextPermission('search_engine') === 'write'
    },

    isCreditCardSelected () {
      return this.formData.paymentType?.includes(1)
    },

    cantSaveFinancialSettings () {
      return (this.formData.financial_provider === undefined || this.formData.financial_provider === null) ||
        (this.formData.paymentType === null || this.formData.paymentType.length === 0) ||
        this.minimumInstallmentValueHasError || this.maximumAmountInstallmentsIsInvalid ||
        this.rmConfigMisfilled
    },

    rmConfigMisfilled () {
      return !!this.formData.rm_enable_integration &&
              (!this.formData.rm_provider ||
              !this.formData.rm_url ||
              !this.formData.rm_user ||
              !this.formData.rm_password ||
              !this.formData.rm_password_base64)
    },

    minimumInstallmentValueHasError () {
      return this.formData.financial_provider &&
        this.formData.financial_credit_card_allows_installment &&
        (this.formData.financial_credit_card_minimum_installment_value === 0 ||
          this.formData.financial_credit_card_minimum_installment_value === 'R$ 0,00')
    },

    maximumAmountInstallmentsIsInvalid () {
      return this.formData.financial_provider &&
        this.formData.financial_credit_card_allows_installment &&
        (this.formData.financial_credit_card_maximum_amount_installments === null ||
          this.formData.financial_credit_card_maximum_amount_installments === undefined ||
          this.formData.financial_credit_card_maximum_amount_installments === '')
    },

    isEcommerceEnabled () {
      return this.activeModules.includes('ecommerce')
    },

    isRmEnabled () {
      return this.activeModules.includes('rm')
    },

    isSasEnabled () {
      return this.activeModules.includes('sas')
    },

    isStudionEnabled () {
      return this.activeModules.includes('studion')
    },

    isDotMailerEnabled () {
      return this.activeModules.includes('dot-mailer')
    },

    isGdhEnabled () {
      return this.activeModules.includes('gdh')
    },

    isCornerstoneEnabled () {
      return this.activeModules.includes('cornerstone')
    },

    hasStudionMetasRelationship () {
      return this.isStudionEnabled &&
        (this.formData.studion_metas_relationship &&
        typeof this.formData.studion_metas_relationship === 'object' &&
        this.formData.studion_metas_relationship.length > 0)
    }
  },

  watch: {
    'formData.sas_id_project' (event) {
      this.setFetching(true)
      this.sasActionList = []

      this.getSasActionList(event).finally(() => {
        this.setFetching(false)
      })
    },

    'searchable_attributes' () {
      this.formData.search_engine_searchable_attributes = ''

      this.searchable_attributes.forEach(element => {
        this.formData.search_engine_searchable_attributes += `${element} `
      })
    },

    'formData.financial_provider' (event) {
      if (event === null) {
        this.resetFinancialFields()
      }
    },

    'formData.financial_credit_card_allows_installment' (event) {
      if (event === false) {
        this.resetCreditCardInstallmentFields()
      }
    },

    'formData.rm_enable_integration' (event) {
      if (event === false) {
        this.resetRmIntegrationFields()
      }
    },

    'formData.studion_metas' () {
      if (!this.formData.studion_metas || this.hasStudionMetasRelationship) return
      this.createMetasRelationship()
    }
  },

  created () {
    if (this.canWriteSearchEngine) {
      this.generalTabs.push({
        text: this.$t('management.tabs:link.search_engine'),
        alias: 'search'
      })
    }

    if (this.isEcommerceEnabled) {
      this.generalTabs.push({
        text: this.$t('management.tabs:link.payments'),
        alias: 'financial'
      })
    }

    if (this.isSasEnabled) {
      this.generalTabs.unshift({
        text: this.$t('management.tabs:link.SAS'),
        alias: 'sas'
      })
    }

    if (this.isStudionEnabled) {
      this.generalTabs.push({
        text: this.$t('management.tabs:link.studion'),
        alias: 'studion'
      })

      this.attemptGetUsersData().then(data => {
        this.vitrineOptions = data.data.map(item => {
          return {
            value: item,
            text: item
          }
        })
      })
    }

    this.generalTabs.push({
      text: this.$t('management.tabs:link.sparkpost'),
      alias: 'sparkpost'
    })

    if (this.isDotMailerEnabled) {
      this.generalTabs.push({
        text: this.$t('management.tabs:link.dot.mailer'),
        alias: 'dot-mailer'
      })
    }

    if (this.isGdhEnabled) {
      this.generalTabs.push({
        text: this.$t('management.tabs:link.gdh'),
        alias: 'gdh'
      })
    }

    if (this.isCornerstoneEnabled) {
      this.generalTabs.push({
        text: this.$t('management.tabs:link.cornerstone'),
        alias: 'cornerstone'
      })
    }

    this.changeTab(this.generalTabs[0].alias)

    if (this.isEcommerceEnabled && this.$route.params.tab === 'finance') {
      this.changeTab('financial')
    }

    this.setup()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetGeneralSettings',
      'attemptSaveGeneralSettings',
      'attemptGetSasUnityList',
      'attemptGetSasActionList',
      'attemptGetSasProjectList',
      'attemptGetProviderList',
      'attemptGetUsersData',
      'attemptGetRmProviderList',
      'attemptGetFinancialSettings',
      'attemptSaveFinancialSettings'
    ]),

    ...mapActions('FinanceModule', [ 'attemptSyncAllOrders' ]),

    setup () {
      this.setFetching(true)

      Promise.all([
        this.isSasEnabled ? this.getSasUnityList() : null,
        this.isSasEnabled ? this.getSasProjectList() : null,
        this.getProviderList(),
        this.getRmProviderList()
      ]).finally(() => {
        this.attemptGetGeneralSettings().then(({ data }) => {
          data.data.forEach(config => {
            this.formData[config.field] = config.active ? config.value ? config.value : config.data : ''
            this.editable[config.field] = config.editable

            if (config.active && config.field === 'sas_id_unity') {
              this.formData[config.field] = parseInt(config.value, 10)
            }

            if (config.active && config.field === 'sas_id_project') {
              this.formData[config.field] = parseInt(config.value, 10)
            }

            if (config.active && config.field === 'sas_id_action') {
              this.formData[config.field] = parseInt(config.value, 10)
            }

            if (config.active && config.field === 'search_engine_stop_words') {
              let parsedStopWords = this.parseStopWords(config.value)

              parsedStopWords = JSON.parse(JSON.stringify(parsedStopWords))
              this.formData[config.field] = parsedStopWords
            }

            if (config.active && config.field === 'financial_provider') {
              this.formData[config.field] = config.value
            }

            if (config.active && config.field === 'financial_credit_card_allows_installment') {
              this.formData[config.field] = parseInt(config.value) === 1 || config.value === true ||
                config.value === 'true'
            }

            if (config.active && config.field === 'rm_enable_integration') {
              this.formData[config.field] = parseInt(config.value) === 1 || config.value === true ||
                  config.value === 'true'
            }

            if (config.field.includes('allows') && config.field !== 'financial_credit_card_allows_installment') {
              const field = this.paymentTypeList.filter(item => item.alias === config.field && config.value === 'true')

              if (this.formData.paymentType === null || this.formData.paymentType === undefined) {
                this.formData.paymentType = []
              }

              if (field.length) {
                this.formData.paymentType.push(field.shift().value)
              }
            }

            if (config.active && config.field === 'financial_receipt') {
              this.formData[config.field] = config.value
            }

            if (config.active && config.field === 'studion_metas') {
              const hasStudionMetasRelationship = data.data.filter(item => item.field === 'studion_metas_relationship').length === 1
              const formattedMetas = JSON.parse(config.data)

              this.formData[config.field] = formattedMetas

              if (!hasStudionMetasRelationship) {
                this.createMetasRelationship()
              }
            }

            if (config.active && config.field === 'studion_metas_relationship') {
              const formattedMetas = JSON.parse(config.data)

              if (formattedMetas && formattedMetas.length > 0) {
                this.formData[config.field] = formattedMetas
              } else {
                this.createMetasRelationship()
              }
            }
          })

          this.parseSynonyms()
          this.parseAttribute()
        }).finally(() => {
          this.setFetching(false)
        })
      })
    },

    changeTab (alias) {
      this.activeTab = alias
    },

    submitSAS () {
      this.stringifySynonyms()
      const configurations = []
      const sendEmptyConfig = [
        'search_engine_stop_words',
        'search_engine_searchable_attributes',
        'search_engine_synonyms',
        'studion_default_branch_name',
        'studion_branch_name',
        'studion_branch_level'
      ]

      const optionalFields = [
        'studion_default_branch_name',
        'studion_branch_name',
        'studion_branch_level'
      ]

      Object.keys(this.formData).forEach(key => {
        let value = this.formData[key]
        const active = !!value

        if (active === false && sendEmptyConfig.includes(key)) {
          value = 'null'
        }

        if (!!value &&
          (value !== 'null' || key === 'search_engine_stop_words' || optionalFields.includes(key)) &&
          key !== 'sas_client_api' &&
          key !== 'sas_client_token' &&
          !key.includes('financial') &&
          !key.includes('system') &&
          key !== 'paymentType') {
          configurations.push({
            field: key,
            value: key === 'search_engine_stop_words' && value !== 'null'
              ? Object.keys(value).length === 1
                ? value[0]
                : JSON.parse(value).map(item => item.value).join()
              : value,
            data: key === 'studion_metas_relationship' ? value : null,
            active: active
          })
        }
      })

      this.setFetching(true)

      this.attemptSaveGeneralSettings({ configurations: JSON.stringify(configurations) })
        .then(() => {
          this.setFeedback({ message: this.$t('management.general:submit.feedback.success') })
        })
        .catch((e) => {
          if (e.data.error.message === 'invalid_date_data_de_nascimento') {
            this.setFeedback({ message: this.$t('management.general:submit.error.studion.birth_of_date') })
          } else {
            this.setFeedback({ message: this.$t('management.general:submit.feedback.error') })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitFinancialConfiguration () {
      const configurations = []

      Object.keys(this.formData).forEach(key => {
        let value = this.formData[key]

        let active = !!value

        if (active === false && key !== 'financial_credit_card_allows_installment' && key !== 'rm_enable_integration') {
          value = 'null'
        }

        if (key === 'financial_credit_card_allows_installment') {
          value = `${value}`
          active = true
        }

        if (key === 'financial_credit_card_minimum_installment_value') {
          value = this.unformat(value)
          value = (value === '0' || value === 0 || value === 0.0) ? '0.00' : value
        }

        if ([
          'rm_provider',
          'rm_url',
          'rm_user',
          'rm_password',
          'rm_password_base64'
        ].includes(key)) {
          value = (isNull(value) || value === 'null' || value === '') ? ' ' : value
        }

        if (key === 'rm_enable_integration') {
          value = `${value}`
          active = true
        }

        !!value && value !== 'null' && !key.includes('sas') && !key.includes('search') && !key.includes('studion') && !key.includes('system') && configurations.push({
          field: key,
          value: value,
          active: active
        })
      })

      this.setFetching(true)

      this.attemptSaveFinancialSettings({ configurations: JSON.stringify(configurations) })
        .then(() => {
          this.setFeedback({ message: this.$t('management.general:submit.feedback.success') })

          if (this.formData.rm_enable_integration === true) {
            this.modalRm = true
          }
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('management.general:submit.feedback.error') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    hasAttribute (attribute) {
      return this.searchable_attributes.includes(attribute)
    },

    changeAttribute (attribute) {
      if (this.hasAttribute(attribute) === false) {
        this.addAttribute(attribute)

        return
      }

      this.removeAttribute(attribute)
    },

    addAttribute (attribute) {
      this.searchable_attributes.push(attribute)
    },

    removeAttribute (attribute) {
      this.searchable_attributes = this.searchable_attributes.filter(element => {
        return (element !== attribute)
      })
    },

    parseAttribute () {
      if (this.formData.search_engine_searchable_attributes === null) {
        return
      }

      this.formData.search_engine_searchable_attributes.split(' ').forEach(element => {
        this.searchable_attributes.push(element)
      })
    },

    addSynonyms () {
      this.synonyms.push({ item: null })
    },

    removeSynonym (index) {
      this.synonyms.splice(index, 1)
    },

    stringifySynonyms () {
      this.formData.search_engine_synonyms = ''

      this.synonyms.forEach(element => {
        this.formData.search_engine_synonyms += element.item + '\n'
      })
    },

    parseStopWords (configValue) {
      return configValue.split(',')
    },

    parseSynonyms () {
      if (this.formData.search_engine_synonyms === null) {
        return
      }

      this.formData.search_engine_synonyms.split('\n').forEach(element => {
        this.synonyms.push({ item: element })
      })
    },

    getSasUnityList () {
      return this.attemptGetSasUnityList({
        filter: {
          active: 'true',
          id_type: 5
        },
        order: { description: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.sasUnityList.push({
              text: element.vendorId + ' - ' + element.description,
              value: element.id
            })
          })

          return true
        }
      })
    },

    getSasActionList (sasProjectId) {
      return this.attemptGetSasActionList({
        filter: {
          active: 'true',
          project: sasProjectId
        },
        order: { description: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.sasActionList.push({
              text: element.vendorId + ' - ' + element.description,
              value: element.id
            })
          })

          return true
        }
      })
    },

    getSasProjectList () {
      return this.attemptGetSasProjectList({
        filter: {
          active: 'true',
          action: 'true'
        },
        order: { description: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.sasProjectList.push({
              text: element.vendorId + ' - ' + element.description,
              value: element.id
            })
          })

          return true
        }
      })
    },

    getProviderList () {
      if (!this.isEcommerceEnabled) return null

      return this.attemptGetProviderList({
        filter: {
          active: 'true',
          action: 'true'
        },
        order: { name: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.providerList.push({
              text: element.name,
              value: element.alias
            })
          })

          return true
        }
      })
    },

    getRmProviderList () {
      return this.attemptGetRmProviderList({
        filter: {
          active: 'true',
          action: 'true'
        },
        order: { name: 'ASC' }
      }).then(pagination => {
        if (pagination.data) {
          pagination.data.forEach(element => {
            this.rmList.push({
              text: element.name,
              value: element.alias
            })
          })

          return true
        }
      })
    },

    isEditable (attribute) {
      return this.editable[attribute] === true || this.editable[attribute] === undefined
    },

    resetFinancialFields () {
      this.formData.financial_credit_card_allows_installment = false
      this.formData.financial_allows_credit_card = false
      this.formData.paymentType = null

      this.resetCreditCardInstallmentFields()
    },

    resetCreditCardInstallmentFields () {
      this.formData.financial_credit_card_maximum_amount_installments = 2
      this.formData.financial_credit_card_minimum_installment_value = '0'
    },

    resetRmIntegrationFields () {
      this.formData.rm_enable_integration = false
      this.formData.rm_provider = ''
      this.formData.rm_url = ''
      this.formData.rm_user = ''
      this.formData.rm_password = ''
      this.formData.rm_password_base64 = ''
    },

    unformat (value) {
      const negative = value.indexOf('-') >= 0 ? -1 : 1
      const numbers = value.toString().replace(/\D+/g, '') || '0'
      const currency = this.numbersToCurrency(numbers)

      return parseFloat(currency) * negative
    },

    numbersToCurrency (numbers) {
      const exp = Math.pow(10, this.money.precision)
      const float = parseFloat(numbers) / exp

      return float.toFixed(this.fixed(this.money.precision))
    },

    fixed () {
      return this.between(0, this.money.precision, 20)
    },

    between (min, n, max) {
      return Math.max(min, Math.min(n, max))
    },

    syncRm () {
      this.modalRm = false
      this.setFetching(true)

      this.attemptSyncAllOrders().then(() => {
        this.setFeedback({ message: this.$t('management.general:feedback.syncall.success') })
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.general:feedback.syncall.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    cancelRemove () {
      this.modalRm = false
    },

    changePattern (index, relationshipItem) {
      if (relationshipItem.pattern === false) {
        relationshipItem.vitrine = null

        if (relationshipItem.options === null) {
          relationshipItem.options = this.formData.studion_metas[index].parameters.options.map(item => {
            return {
              id: item.id,
              value: null
            }
          })
        }

        relationshipItem.options.map(item => {
          item.value = null

          return item
        })
      }

      this.$set(this.formData.studion_metas_relationship, index, relationshipItem)
    },

    createMetasRelationship () {
      if (!this.formData.studion_metas || this.hasStudionMetasRelationship) return

      const studionMetasRelationship = this.formData.studion_metas.map((item) => {
        const itemObj = {
          meta: item.alias,
          vitrine: null,
          pattern: !!item.parameters.options
        }

        if (item.parameters.options) {
          itemObj.options = item.parameters.options.map(option => {
            return {
              id: option.id,
              value: null
            }
          })
        }

        return itemObj
      })

      this.$set(this.formData, 'studion_metas_relationship', studionMetasRelationship)
    },

    getStandardOptions (options) {
      if (!options) return []

      return options.map(item => {
        return {
          value: item.id,
          text: item.fields.name
        }
      })
    },

    getSelectOptions (propsItem, relationshipItem) {
      if (propsItem.type.alias === 'select' && relationshipItem && relationshipItem.pattern === false) {
        return this.getStandardOptions(propsItem.parameters.options)
      } else {
        return this.vitrineOptions
      }
    }
  }
}
</script>

<template>
  <div class="main-content management-general">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="availableTabLink"
      />
    </ContentHeader>

    <div
      class="center-small general-settings"
      :class="{'center-large': activeTab === 'studion'}"
    >
      <FilterList>
        <Tabs
          slot="button"
          class="gamification-tabs"
          :links="generalTabs"
          :alias-active="activeTab"
          use-alias
          @tabChange="changeTab"
        />
      </FilterList>

      <form
        v-if="activeTab === 'sas' && isSasEnabled"
        class="management-settings_form"
        @submit.prevent="submitSAS"
      >
        <SelectField
          v-model="formData.sas_id_project"
          :label="$t('management.general:field.name.sas_id_project')"
          :items="sasProjectList"
          :validation="$v.formData.sas_id_project"
          :disabled="!isEditable('sas_id_project')"
        />

        <SelectField
          v-model="formData.sas_id_action"
          :label="$t('management.general:field.name.sas_id_action')"
          :items="sasActionList"
          :validation="$v.formData.sas_id_action"
          :disabled="!isEditable('sas_id_action')"
        />

        <SelectField
          v-model="formData.sas_id_unity"
          :label="$t('management.general:field.name.sas_id_unity')"
          :under-description="$t('solution.manage:field.description.sasUnity.code')"
          :items="sasUnityList"
          :validation="$v.formData.sas_id_unity"
          :disabled="!isEditable('sas_id_unity')"
        />

        <div class="row no-margin">
          <InputField
            v-model="formData.sas_notify_email"
            :label="$t('management.general:field.name.sas_notify_email')"
            :validation="$v.formData.sas_notify_email"
            :disabled="!isEditable('sas_notify_email')"
          />
          <InputField
            v-model="formData.sas_id_service_type"
            :label="$t('management.general:field.name.sas_id_service_type')"
            :validation="$v.formData.sas_id_service_type"
            :disabled="!isEditable('sas_id_service_type')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_id_modality"
            :label="$t('management.general:field.name.sas_id_modality')"
            :validation="$v.formData.sas_id_modality"
            :disabled="!isEditable('sas_id_modality')"
          />
          <InputField
            v-model="formData.sas_id_instrument"
            :label="$t('management.general:field.name.sas_id_instrument')"
            :validation="$v.formData.sas_id_instrument"
            :disabled="!isEditable('sas_id_instrument')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_id_responsable"
            :label="$t('management.general:field.name.sas_id_responsable')"
            :validation="$v.formData.sas_id_responsable"
            :disabled="!isEditable('sas_id_responsable')"
          />
          <InputField
            v-model="formData.sas_id_attendance"
            :label="$t('management.general:field.name.sas_id_attendance')"
            :validation="$v.formData.sas_id_attendance"
            :disabled="!isEditable('sas_id_attendance')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_id_credentiated_employee"
            :label="$t('management.general:field.name.sas_id_credentiated_employee')"
            :validation="$v.formData.sas_id_credentiated_employee"
            :disabled="!isEditable('sas_id_credentiated_employee')"
          />
          <InputField
            v-model="formData.sas_name_instructure"
            :label="$t('management.general:field.name.sas_name_instructure')"
            :validation="$v.formData.sas_name_instructure"
            :disabled="!isEditable('sas_name_instructure')"
          />
        </div>
        <InputField
          v-model="formData.sas_address"
          :label="$t('management.general:field.name.sas_address')"
          :validation="$v.formData.sas_address"
          :disabled="!isEditable('sas_address')"
        />
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_complement"
            :label="$t('management.general:field.name.sas_complement')"
            :validation="$v.formData.sas_complement"
            :disabled="!isEditable('sas_complement')"
          />
          <InputField
            v-model="formData.sas_cep"
            :label="$t('management.general:field.name.sas_cep')"
            :validation="$v.formData.sas_cep"
            :disabled="!isEditable('sas_cep')"
          />
        </div>
        <div class="row no-margin">
          <InputField
            v-model="formData.sas_city_code"
            :label="$t('management.general:field.name.sas_city_code')"
            :validation="$v.formData.sas_city_code"
            :disabled="!isEditable('sas_city_code')"
          />
          <InputField
            v-model="formData.sas_neighborhood_code"
            :label="$t('management.general:field.name.sas_neighborhood_code')"
            :validation="$v.formData.sas_neighborhood_code"
            :disabled="!isEditable('sas_neighborhood_code')"
          />
          <InputField
            v-model="formData.sas_state_code"
            :label="$t('management.general:field.name.sas_state_code')"
            :validation="$v.formData.sas_state_code"
            :disabled="!isEditable('sas_state_code')"
          />
        </div>
        <Action
          class="col-6"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>

      <form
        v-if="canWriteSearchEngine && activeTab === 'search'"
        @submit.prevent="submitSAS"
      >
        <FormSection :title="$t('management.general:field.name.search_engine.stop_words')">
          <Tagify
            v-model="formData.search_engine_stop_words"
            :placeholder="$t('global:form.type.here')"
          />
        </FormSection>

        <FormSection :title="$t('management.general:field.name.search_engine.searchable_attributes')">
          <ul class="tags-list">
            <li
              v-for="(item, index) in searchableAttributesList"
              :key="index"
              class="tag-item"
            >
              <Action
                :text="$t(`management.general:searchengine.attribute.${item.value}`)"
                primary
                type="button"
                :class="{active: hasAttribute(item.value)}"
                @click="changeAttribute(item.value)"
              />
            </li>
          </ul>
        </FormSection>

        <FormSection
          v-show="false"
          :title="$t('management.general:field.name.search_engine.synonyms')"
        >
          <ul class="question-answers-items">
            <li
              v-for="(answer, index) in synonyms"
              :key="index"
              class="question-answers-item flex-row"
            >
              <InputField
                v-model="answer.item"
                :label="$t('management.general:field.name.search_engine.synonyms')"
              />
              <Action
                :text="$t('global:form.management.survey.questions.answer.remove')"
                icon="delete"
                icon-right
                flat
                type="button"
                @click="removeSynonym(index)"
              />
            </li>
          </ul>
          <Action
            :text="$t('management.general:search_engine.add.synonyms')"
            primary
            type="button"
            @click="addSynonyms()"
          />
        </FormSection>

        <FormSection class="form-section-center">
          <Action
            class="col-6"
            type="button"
            secondary
            large
            submit
            :text="$t('global:form.save')"
          />
        </FormSection>
      </form>

      <form
        v-if="activeTab === 'financial' && isEcommerceEnabled"
        @submit.prevent="submitFinancialConfiguration"
      >
        <FormSection :title="$t('management.general:field.name.config.payment')">
          <SelectField
            v-model="formData.financial_provider"
            :label="$t('management.general:field.name.financial_provider')"
            :items="providerList"
            :validation="$v.formData.financial_provider"
            :disabled="!isEditable('financial_provider')"
          />

          <SelectField
            v-if="formData.financial_provider"
            v-model="formData.paymentType"
            :label="$t('management.general:field.name.paymentType')"
            :items="paymentTypeList"
            multiple
            :validation="$v.formData.paymentType"
            :disabled="!isEditable('paymentType')"
          />
        </FormSection>

        <FormSection
          v-if="formData.financial_provider && isCreditCardSelected"
          :title="$t('management.general:field.name.financial_credit_card_allows_installment')"
        >
          <Radio
            v-model="formData.financial_credit_card_allows_installment"
            :items="yesNoOptions"
            horizontal
            :validation="$v.formData.financial_credit_card_allows_installment"
          />
        </FormSection>

        <div
          v-if="formData.financial_provider && isCreditCardSelected
            && formData.financial_credit_card_allows_installment"
          class="row no-margin"
        >
          <FormSection class="form-section-center col-4">
            <div class="form-item has-value has-floating-label">
              <label
                class="form-label"
              >{{ $t('management.general:field.name.financial_credit_card_minimum_installment_value') }}
              </label>
              <div class="form-input-wrapper">
                <input
                  v-model="formData.financial_credit_card_minimum_installment_value"
                  v-money="money"
                  class="form-input"
                  type="text"
                >
              </div>
              <div
                v-if="minimumInstallmentValueHasError"
                class="form-input-details"
              >
                <div class="form-input-messages-container">
                  <span class="icon-wrapper is-small">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-error"
                    >
                      <use xlink:href="#icon-error" />
                    </svg>
                  </span>
                  <span class="form-input-message">{{ $t('global:validation.required') }}</span>
                </div>
              </div>
            </div>
          </FormSection>

          <FormSection class="form-section-center col-4">
            <InputField
              v-model="formData.financial_credit_card_maximum_amount_installments"
              type="number"
              :min="2"
              :max="12"
              :label="$t('management.general:field.name.financial_credit_card_maximum_amount_installments')"
              :validation="$v.formData.financial_credit_card_maximum_amount_installments"
            />
          </FormSection>
        </div>

        <SelectField
          v-model="formData.financial_receipt"
          :label="$t('management.general:field.name.financial_receipt')"
          :items="receiptList"
          :validation="$v.formData.financial_receipt"
          :disabled="!isEditable('financial_receipt')"
        />

        <FormSection
          v-if="isRmEnabled"
          :title="$t('management.general:field.name.rm_enable_integration')"
        >
          <Radio
            v-model="formData.rm_enable_integration"
            :items="yesNoOptions"
            horizontal
            :validation="$v.formData.rm_enable_integration"
          />
        </FormSection>

        <FormSection v-if="formData.rm_enable_integration">
          <SelectField
            v-model="formData.rm_provider"
            :label="$t('management.general:field.name.rm_provider')"
            :items="rmList"
            :validation="$v.formData.rm_provider"
            :disabled="!isEditable('rm_provider')"
          />
        </FormSection>

        <FormSection
          v-if="formData.rm_enable_integration"
          :title="$t('management.general:field.name.rm_credentials')"
        >
          <InputField
            v-model="formData.rm_url"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_url')"
            :validation="$v.formData.rm_url"
          />

          <InputField
            v-model="formData.rm_user"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_user')"
            :validation="$v.formData.rm_user"
          />

          <InputField
            v-model="formData.rm_password"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_password')"
            :validation="$v.formData.rm_password"
          />

          <InputField
            v-model="formData.rm_password_base64"
            type="text"
            :label="$t('management.general:field.name.rm_credentials_password_base64')"
            :validation="$v.formData.rm_password_base64"
          />
        </FormSection>

        <FormSection class="form-section-center">
          <Action
            class="col-6"
            type="button"
            secondary
            large
            submit
            :text="$t('global:form.save')"
            :disabled="cantSaveFinancialSettings"
          />
        </FormSection>
      </form>

      <form
        v-if="activeTab === 'studion' && isStudionEnabled"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.studion_api_url"
          :label="$t('management.general:field.name.studion_api_url')"
          :validation="$v.formData.studion_api_url"
          :disabled="!isEditable('studion_api_url')"
        />

        <InputField
          v-model="formData.studion_client_id"
          :label="$t('management.general:field.name.studion_client_id')"
          :validation="$v.formData.studion_client_id"
          :disabled="!isEditable('studion_client_id')"
        />

        <InputField
          v-model="formData.studion_client_secret"
          :label="$t('management.general:field.name.studion_client_secret')"
          :validation="$v.formData.studion_client_secret"
          :disabled="!isEditable('studion_client_secret')"
        />

        <InputField
          v-model="formData.studion_login"
          :label="$t('management.general:field.name.studion_login')"
          :validation="$v.formData.studion_login"
          :disabled="!isEditable('studion_login')"
        />

        <InputField
          v-model="formData.studion_passwd"
          :label="$t('management.general:field.name.studion_passwd')"
          :validation="$v.formData.studion_passwd"
          :disabled="!isEditable('studion_passwd')"
        />

        <InputField
          v-model="formData.studion_spa_url"
          :label="$t('management.general:field.name.studion_spa_url')"
          :validation="$v.formData.studion_spa_url"
          :disabled="!isEditable('studion_spa_url')"
        />

        <InputField
          v-model="formData.studion_default_branch_name"
          :label="$t('management.general:field.name.default_branch_name')"
          :disabled="!isEditable('studion_default_branch_name')"
        />

        <InputField
          v-model="formData.studion_branch_name"
          :label="$t('management.general:field.name.branch_name')"
          :disabled="!isEditable('studion_branch_name')"
        />

        <InputField
          v-model="formData.studion_branch_level"
          :label="$t('management.general:field.name.branch_level')"
          :disabled="!isEditable('studion_branch_level')"
        />

        <FormSection
          v-if="hasStudionMetasRelationship"
          class="form-section-studion"
          :title="$t('management.general:field.name.metadata')"
        >
          <Datatable :items="formData.studion_metas">
            <template slot="thead">
              <tr>
                <th
                  class="th"
                  width="20%"
                >
                  {{ $t('management.general:metadata.datatable.header.1') }}
                </th>

                <th
                  class="th"
                  width="10%"
                >
                  {{ $t('management.general:metadata.datatable.header.2') }}
                </th>

                <th
                  class="th text-center"
                  width="15%"
                >
                  {{ $t('management.general:metadata.datatable.header.3') }}
                </th>

                <th
                  class="th text-center"
                  width="25%"
                >
                  {{ $t('management.general:metadata.datatable.header.4') }}
                </th>

                <th
                  class="th text-center"
                  width="30%"
                >
                  {{ $t('management.general:metadata.datatable.header.5') }}
                </th>
              </tr>
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td class="td">
                  <span class="td-text bolder">{{ props.item.name }}</span>
                </td>

                <td class="td">
                  <span class="td-text">{{ props.item.type.description }}</span>
                </td>

                <td class="td text-center">
                  <input
                    type="checkbox"
                    :checked="props.item.parameters.required"
                    disabled
                  >
                </td>

                <td class="td text-center">
                  <span
                    v-show="props.item.type.alias !== 'select'"
                    class="td-text"
                  >
                    {{ $t('management.general:metadata.datatable.field.non-aplicable') }}
                  </span>

                  <Radio
                    v-if="props.item.type.alias === 'select' && hasStudionMetasRelationship"
                    v-model="formData.studion_metas_relationship[props.index].pattern"
                    :items="patternOptions"
                    horizontal
                    @input="changePattern(props.index, formData.studion_metas_relationship[props.index])"
                  />
                </td>

                <td class="td text-center">
                  <SelectField
                    v-if="hasStudionMetasRelationship"
                    v-model="formData.studion_metas_relationship[props.index].vitrine"
                    class="studion-vitrine-field"
                    :label="$t('management.general:metadata.datatable.field.select')"
                    :items="getSelectOptions(props.item, formData.studion_metas_relationship[props.index])"
                    :validation="$v.formData.studion_metas"
                  />
                </td>
              </tr>

              <tr
                v-show="formData.studion_metas_relationship[props.index] && formData.studion_metas_relationship[props.index].pattern"
                class="tr-padded"
              >
                <td />
                <td />
                <td />

                <td>
                  <h2>Valor</h2>
                  <ul>
                    <li
                      v-for="(option, optionIndex) in props.item.parameters.options"
                      :key="optionIndex"
                    >
                      <InputField
                        v-model="option.fields.name"
                        type="text"
                        disabled
                      />
                    </li>
                  </ul>
                </td>

                <td>
                  <h2>Associação</h2>

                  <ul v-if="formData.studion_metas_relationship[props.index]">
                    <li
                      v-for="(option, optionIndex) in formData.studion_metas_relationship[props.index].options"
                      :key="optionIndex"
                    >
                      <InputField
                        v-model="option.value"
                        type="text"
                      />
                    </li>
                  </ul>
                </td>
              </tr>
            </template>
          </Datatable>
        </FormSection>

        <Action
          class="col-4 btn center"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>

      <form
        v-if="activeTab === 'gdh'"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.gdh_url"
          :label="$t('management.general:field.name.gdh_url')"
          :validation="$v.formData.gdh_url"
          :disabled="!isEditable('gdh_url')"
        />
        <InputField
          v-model="formData.gdh_username"
          :label="$t('management.general:field.name.gdh_username')"
          :validation="$v.formData.gdh_username"
          :disabled="!isEditable('gdh_username')"
        />
        <InputField
          v-model="formData.gdh_password"
          :label="$t('management.general:field.name.gdh_password')"
          :validation="$v.formData.gdh_password"
          :disabled="!isEditable('gdh_password')"
        />
        <Action
          class="col-6"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>

      <form
        v-if="activeTab === 'sparkpost'"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.sparkpost_api_url"
          :label="$t('management.general:field.name.sparkpost_api_url')"
          :validation="$v.formData.sparkpost_api_url"
          :disabled="!isEditable('sparkpost_api_url')"
        />
        <InputField
          v-model="formData.sparkpost_key"
          :label="$t('management.general:field.name.sparkpost_key')"
          :validation="$v.formData.sparkpost_key"
          :disabled="!isEditable('sparkpost_key')"
        />
        <Action
          class="col-6"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>

      <form
        v-if="activeTab === 'dot-mailer' && isDotMailerEnabled"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.dot_mailer_api_url"
          :label="$t('management.general:field.name.dot_mailer_api_url')"
          :validation="$v.formData.dot_mailer_api_url"
          :disabled="!isEditable('dot_mailer_api_url')"
        />
        <InputField
          v-model="formData.dot_mailer_api_key"
          :label="$t('management.general:field.name.dot_mailer_api_key')"
          :validation="$v.formData.dot_mailer_api_key"
          :disabled="!isEditable('dot_mailer_api_key')"
        />
        <InputField
          v-model="formData.dot_mailer_api_secret"
          :label="$t('management.general:field.name.dot_mailer_api_secret')"
          :validation="$v.formData.dot_mailer_api_secret"
          :disabled="!isEditable('dot_mailer_api_secret')"
        />
        <InputField
          v-model="formData.dot_mailer_from_name"
          :label="$t('management.general:field.name.dot_mailer_from_name')"
          :validation="$v.formData.dot_mailer_from_name"
          :disabled="!isEditable('dot_mailer_from_name')"
        />
        <InputField
          v-model="formData.dot_mailer_from_email"
          :label="$t('management.general:field.name.dot_mailer_from_email')"
          :validation="$v.formData.dot_mailer_from_email"
          :disabled="!isEditable('dot_mailer_from_email')"
        />
        <InputField
          v-model="formData.dot_mailer_reply_to"
          :label="$t('management.general:field.name.dot_mailer_reply_to')"
          :validation="$v.formData.dot_mailer_reply_to"
          :disabled="!isEditable('dot_mailer_reply_to')"
        />
        <InputField
          v-model="formData.dot_mailer_support_recipient"
          :label="$t('management.general:field.name.dot_mailer_support_recipient')"
          :validation="$v.formData.dot_mailer_support_recipient"
          :disabled="!isEditable('dot_mailer_support_recipient')"
        />
        <Action
          class="col-6"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>

      <form
        v-if="activeTab === 'cornerstone'"
        @submit.prevent="submitSAS"
      >
        <InputField
          v-model="formData.cornerstone_api_url"
          :label="$t('management.general:field.name.cornerstone_api_url')"
          :validation="$v.formData.cornerstone_api_url"
          :disabled="!isEditable('cornerstone_api_url')"
        />

        <InputField
          v-model="formData.cornerstone_client_id"
          :label="$t('management.general:field.name.cornerstone_client_id')"
          :validation="$v.formData.cornerstone_client_id"
          :disabled="!isEditable('cornerstone_client_id')"
        />

        <InputField
          v-model="formData.cornerstone_client_secret"
          :label="$t('management.general:field.name.cornerstone_client_secret')"
          :validation="$v.formData.cornerstone_client_secret"
          :disabled="!isEditable('cornerstone_client_secret')"
        />

        <Action
          class="col-6"
          type="button"
          secondary
          large
          :text="$t('global:form.save')"
          submit
        />
      </form>
    </div>

    <ModalConfirm
      :active="modalRm"
      :title="$t('management.general:syncall.title')"
      :description="$t('management.general:syncall.description')"
      @confirmAction="syncRm"
      @cancelAction="cancelRemove"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>

<style lang="scss" scoped>
.management-settings_form {
  .row {
    justify-content: space-between;

    .form-item {
      flex: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.management-general {
  .gamification-tabs {
    display: flex;
    gap: 16px;
  }
  .row {
    justify-content: space-between;

    .form-section {
      margin: 0 15px 0 0;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.form-section {
  margin-top: 0;

  &.form-section-studion .tr-padded td {
    padding: 24px 0 0;
  }
}
</style>
